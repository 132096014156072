.current-step-indicator {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: -5.2rem;
	margin-bottom: 3rem;
	gap: 24px;
	transform: translateY(-50%);

	@media (min-width: 768px) {
		margin-top: -6.2rem;
		gap: 80px;
	}

	&__step {
		position: relative;
		font-size: 1.25rem;
		line-height: 1;
		text-align: center;
		width: 50px;

		@media (min-width: 768px) {
			width: 100px;
		}

		&:not(:last-child)::after {
			content: "";
			position: absolute;
			display: block;
			top: 30px;
			left: -60px;
			width: 60px;
			height: 3.75rem;
			border-radius: .75rem;

			@media (min-width: 768px) {
				top: 68px;
				left: -80px;
				width: 80px;
			}
		}

		>span {
			display: none;

			@media (min-width: 768px) {
				display: block;
			}
		}

		&__number {
			@apply w-12 h-12 mx-auto mt-2 rounded-full flex justify-center items-center bg-white shadow-sm;
			@apply text-blue-200 font-bold;

			@media (min-width: 768px) {
				@apply w-16 h-16;
			}

			@at-root .current-step-indicator__step--current & {
				@apply text-blue-500;
			}

			@at-root .current-step-indicator__step--done & {
				@apply text-blue-600;
			}
		}
	}
}